import React, { useEffect,useState } from "react";
import validator from "validator";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import db from "../../components/Firebase";
import useDocumentTitle from '../../useDocumentTitle';

const ProfilePage = () => {  
    useDocumentTitle('Unobilabs - My profile');  
    let [docid, setId] = useState(localStorage.getItem('id'));      
    let [name, setName] = useState(localStorage.getItem('name'));      
    let [phone, setPhone] = useState(localStorage.getItem('phone'));                  
    let [email, setEmail] = useState(localStorage.getItem('email'));      
    let [doorno, setDoor] = useState(localStorage.getItem('doorno'));      
    let [address, setAddress] = useState(localStorage.getItem('address'));      
    let [district, setDistrict] = useState(localStorage.getItem('district'));      
    let [city, setCity] = useState(localStorage.getItem('city'));      
    let [state, setDetailsState] = useState(localStorage.getItem('state'));      
    let [labname, setLabName] = useState(localStorage.getItem("labname"));
    let [labaddress, setLabAddress] = useState(localStorage.getItem("labaddress"));
    let [pincode, setPincode] = useState(localStorage.getItem("pincode")); 
    let [gst, setGst] = useState(localStorage.getItem('gst'));

    let [names, setNames] = useState(localStorage.getItem('name'));      
    let [namesError, setNamesError] = useState("");
    let [emails, setEmails] = useState(localStorage.getItem('email'));      
    let [doornos, setDoors] = useState(localStorage.getItem('doorno'));     
    let [doornosError, setDoorsError] = useState(""); 
    let [addresss, setAddresss] = useState(localStorage.getItem('address'));      
    let [addresssError, setAddresssError] = useState("");
    let [districts, setDistricts] = useState(localStorage.getItem('district'));      
    let [districtsError, setDistrictsError] = useState("");
    let [citys, setCitys] = useState(localStorage.getItem('city'));      
    let [pincodes, setPincodes] = useState(localStorage.getItem("pincode"));     
    let [gsts, setGsts] = useState(localStorage.getItem('gst'));
    let [citysError, setCitysError] = useState("");
    let [states, setDetailsStates] = useState(localStorage.getItem('state'));     
    let [labnames,setLabNames] = useState(localStorage.getItem('labname'));
    let [labaddresss, setLabAddresss] = useState(localStorage.getItem("labaddress")); 
    let [statesError, setstatesError] = useState("");
    let [emailerror, setEmailError] = useState("");
    let [pincodeerror, setPincodeError] = useState("");    
    let [country, setCountry] = useState(localStorage.getItem('country'));     
    let [profile, setProfile] = useState(localStorage.getItem('profile'));       
    let [earnings, setEarnings] = useState(0); 
    const validateEmail = (e) => {
        var email = e.target.value;
    
        if (validator.isEmail(email)) {
          setEmailError("");
        } else {
          setEmailError("Please provide valid Email.");
        }
    };
    const validateNames = (e) => {
        var name = e.target.value;
        if(name === '')
        {
            setNamesError("Please provide valid Name");
        }   
        else
        {
            setNamesError("");
        }     
    }
    const validateDoor = (e) => {
        var door = e.target.value;
        if(door === '')
        {
            setDoorsError("Please provide valid Door No");
        }   
        else
        {
            setDoorsError("");
        }     
    }  
    const validateAddress = (e) => {
        var address = e.target.value;
        if(address === '')
        {
            setAddresssError("Please provide valid Address");
        }   
        else
        {
            setAddresssError("");
        }     
    }   
    const validateCity = (e) => {
        var city = e.target.value;
        if(city === '')
        {
            setCitysError("Please provide valid City");
        }   
        else
        {
            setCitysError("");
        }     
    }       
    const validateDistrict = (e) => {
        var dist = e.target.value;
        if(dist === '')
        {
            setDistrictsError("Please provide valid District");
        }   
        else
        {
            setDistrictsError("");
        }     
    }    
    const validateState = (e) => {
        var state = e.target.value;
        if(state === '')
        {
            setstatesError("Please provide valid State");
        }   
        else
        {
            setstatesError("");
        }     
    }    
    const validatePincode = (e) => {
        var pincode = e.target.value;
        if(pincode === '')
        {
            setPincodeError("Please provide valid Pincode");
        }   
        else
        {
            setPincodeError("");
        }             
    }
    const logout = () => {
        localStorage.clear();        
        window.location.reload();
    }    
    const onTop = () => {
        window.scrollTo(0, 0);
    };
    const showEditable = () => {
        document.getElementById("withedit").style.display = "block";
        document.getElementById("withoutedit").style.display = "none";
        document.getElementById("pencil_close").style.display = "block";
        document.getElementById("pencil").style.display = 'none';
    }
    const showDetails = () => {
        document.getElementById("withedit").style.display = "none";
        document.getElementById("withoutedit").style.display = "block";
        document.getElementById("pencil_close").style.display = "none";
        document.getElementById("pencil").style.display = 'block';        
    }
    const showToast = (types) => {
        if(types == "failed")
        {
          var x = document.getElementById("snackbar");
          x.className = "show";
          x.style.backgroundColor = "#de3d3d";      
          setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);            
          document.getElementById("snackbar").innerText = "Something wrong";
        }
        else
        {
          var x = document.getElementById("snackbar");
          x.className = "show";
          x.style.backgroundColor = "#21b446";
          setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);            
          document.getElementById("snackbar").innerText = "Successfully Updated";
        }
    }    
    const gotoOrders = () => {
        window.location.href = '/earnings';        
    }
    const handleSubmit = async () => {
        await db.collection("unobi_company_users").doc(localStorage.getItem("id")).set({
            "u_name" : names,
            "u_email" : emails,
            "u_door" : doornos,
            "u_address" : addresss,
            "u_district" : districts,
            "u_city" : citys,
            "u_state" : states,
            "u_pincode" : pincodes,
            "l_labname" : labnames,
            "l_labaddress" : labaddresss,
            "u_gst":gsts,
        },{merge : true}).then(snap => {
            showToast("success");
            localStorage.setItem('name',names);      
            localStorage.setItem('email',emails);      
            localStorage.setItem('doorno',doornos);      
            localStorage.setItem('address',addresss);      
            localStorage.setItem('district',districts);      
            localStorage.setItem('city',citys);      
            localStorage.setItem('state',states);      
            localStorage.setItem('labname', labnames);
            localStorage.setItem('labaddress',labaddresss);
            localStorage.setItem("pincode",pincodes);
            localStorage.setItem("gst",gsts);
            setName(names);
            setEmail(emails);
            setDoor(doornos);
            setAddress(addresss);
            setDistrict(districts);
            setCity(citys);
            setDetailsState(states);
            setLabName(labnames);
            setLabAddress(labaddresss);
            setPincode(pincodes);
            setGst(gsts);
            document.getElementById("withedit").style.display = "none";
            document.getElementById("withoutedit").style.display = "block";            
            document.getElementById("pencil_close").style.display = "none";
            document.getElementById("pencil").style.display = 'block';                    
        }).catch(err => {
            showToast("failed");
        })
    }
    const fetchData = async ()  => {
        document.getElementById("loader").style.display = "block";                
        await db.collection("unobi_company_users").where("com_doc_id","==",localStorage.getItem("id")).get().then((querySnapshot) => {             
          querySnapshot.forEach(element => {
            let data = element.data();
            setEarnings(data['store_credit_total']);
          });
        });        
        document.getElementById("loader").style.display = "none";                        
    }   
    useEffect(() => {
        onTop();
        fetchData();
    }, []);
  return (
    <React.Fragment>
      <Header name="ProfilePage"></Header>
      <div className="loader" id="loader" style={{ display: "none" }}></div>                  
      <section
        className="features-hero mt-5"
        style={{ "padding-top":"25px",
          backgroundImage: `url("../assets/images/banners/dashboard_banner.jpg")`,
        }}
      >
        <div className="container py-5">
          <div className="row py-3">
            <div className="col-12 text-center">
            <p className="feature-hero-head">Dashboard</p>
              <p className="feature-hero-sub-head">{localStorage.getItem("name")}</p>
            </div>
          </div>
        </div>
      </section>      
      <div className="container">
      <div class="row">
        <div class="col-lg-4">
            <div class="card mb-4">
            <div class="card-body text-center">
                <img src={"data:image/jpeg;base64," + profile} alt="avatar"
                class="rounded-circle img-fluid" style={{"width": "140px","height":"140px"}}/>
                <h5 class="my-3">{name}</h5>
                <p class="text-muted mb-1">{phone}</p>
                <p class="text-muted mb-4">{email}</p>
                <div class="d-flex justify-content-center mb-2">
                <button type="button" onClick={gotoOrders} class="btn btn-primary" style={{"width": "140px","background-color": "#373091!important"}}>Uno Coins {earnings}</button>
{/*                <button type="button" onClick={logout} class="btn ms-1" style={{"width" :"140px","border-color": "#373091","color": "#373091"}}>Logout</button>*/}
                </div>
            </div>
            </div>
        
        </div>
        <div class="col-lg-8">
            <div class="card mb-4">
            <div class="card-body">
                <div class="row">
                <i class="fa fa-pencil" id="pencil" onClick={showEditable} style={{"width":"100%","text-align":"right","padding-bottom": "10px","display":"block"}}></i>
                <i class="fa fa-close" id="pencil_close" onClick={showDetails} style={{"width":"100%","text-align":"right","padding-bottom": "10px","display" : "none"}}></i>                
                </div>
                <div id="withoutedit" style={{"display" : "block"}}>
                    <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">Lab Name</p>
                    </div>
                    <div class="col-sm-9">
                        <p class="text-muted mb-0">{labname}</p>
                    </div>
                    </div>
                    <hr/>
                    <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">Lab Address</p>
                    </div>
                    <div class="col-sm-9">
                        <p class="text-muted mb-0">{labaddress}</p>
                    </div>
                    </div>
                    <hr/>                                        
                    <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">Full Name</p>
                    </div>
                    <div class="col-sm-9">
                        <p class="text-muted mb-0">{name}</p>
                    </div>
                    </div>
                    <hr/>
                    <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">Email</p>
                    </div>
                    <div class="col-sm-9">
                        <p class="text-muted mb-0">{email}</p>
                    </div>
                    </div>
                    <hr/>
                    <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">Door No / Flat No</p>
                    </div>
                    <div class="col-sm-9">
                        <p class="text-muted mb-0">{doorno}</p>
                    </div>
                    </div>
                    <hr/>
                    <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">address</p>
                    </div>
                    <div class="col-sm-9">
                        <p class="text-muted mb-0">{address}</p>
                    </div>
                    </div>
                    <hr/>
                    <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">city</p>
                    </div>
                    <div class="col-sm-9">
                        <p class="text-muted mb-0">{city}</p>
                    </div>
                    </div>
                    <hr/>
                    <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">District</p>
                    </div>
                    <div class="col-sm-9">
                        <p class="text-muted mb-0">{district}</p>
                    </div>
                    </div>  
                    <hr/>
                    <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">State</p>
                    </div>
                    <div class="col-sm-9">
                        <p class="text-muted mb-0">{state}</p>
                    </div>
                    </div>                              
                    <hr/>
                    <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">Pincode</p>
                    </div>
                    <div class="col-sm-9">
                        <p class="text-muted mb-0">{pincode}</p>
                    </div>
                    </div>                              
                    <hr/>                    
                    <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">Country</p>
                    </div>
                    <div class="col-sm-9">
                        <p class="text-muted mb-0">{country}</p>
                    </div>
                    </div>                              
                    <hr/>                   
                    <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">GST Number</p>
                    </div>
                    <div class="col-sm-9">
                        <p class="text-muted mb-0">{gst}</p>
                    </div>
                    </div>                              
                    <hr/>                                        
                 </div>                    
                 <div id="withedit" style={{"display" : "none"}}>
                 <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">Lab Name</p>
                    </div>
                    <div class="col-sm-9">
                        <input type = "text" id="floatingInput" className="form-control" value={labnames} onChange={(e) => setLabNames(e.target.value)}/>                        
                    </div>
                    </div> 
                    <hr/>                                                 
                    <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">Lab Address</p>
                    </div>
                    <div class="col-sm-9">
                        <input type = "text" id="floatingInput" className="form-control" value={labaddresss} onChange={(e) => setLabAddresss(e.target.value)}/>                        
                    </div>
                    </div> 
                    <hr/>                                                 
                    <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">Full Name</p>
                    </div>
                    <div class="col-sm-9">
                        <input type = "text" id="floatingInput" className="form-control" value={names} onChange={(e) => (setNames(e.target.value),validateNames(e))} />
                        <span
                            style={
                                namesError === ""
                                ? {
                                    fontFamily: "raleway",
                                    fontWeight: "600",
                                    color: "#FFFFFF",
                                    fontSize: "14px",
                                  }
                                : {
                                    fontFamily: "raleway",
                                    fontWeight: "400",
                                    color: "#E11B2F",
                                    fontSize: "14px",
                                  }
                            }
                          >
                            {namesError}
                        </span>                                        
                    </div>
                    </div>
                    <hr/>
                    <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">Email</p>
                    </div>
                    <div class="col-sm-9">
                    <input type ="text" id="floatingInput" className="form-control" value={emails} onChange={(e) => (setEmails(e.target.value), validateEmail(e))} />                        
                    <span
                            style={
                                emailerror === ""
                                ? {
                                    fontFamily: "raleway",
                                    fontWeight: "600",
                                    color: "#FFFFFF",
                                    fontSize: "14px",
                                  }
                                : {
                                    fontFamily: "raleway",
                                    fontWeight: "400",
                                    color: "#E11B2F",
                                    fontSize: "14px",
                                  }
                            }
                          >
                            {emailerror}
                    </span>                    
                    </div>
                    </div>
                    <hr/>
                    <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">Door No / Flat No</p>
                    </div>
                    <div class="col-sm-9">
                        <input type = "text" id="floatingInput" className="form-control" value={doornos} onChange={(e) => (setDoors(e.target.value),validateDoor(e))}/>                        
                        <span
                            style={
                                doornosError === ""
                                ? {
                                    fontFamily: "raleway",
                                    fontWeight: "600",
                                    color: "#FFFFFF",
                                    fontSize: "14px",
                                  }
                                : {
                                    fontFamily: "raleway",
                                    fontWeight: "400",
                                    color: "#E11B2F",
                                    fontSize: "14px",
                                  }
                            }
                          >
                            {doornosError}
                        </span>                                                                
                    </div>
                    </div>
                    <hr/>
                    <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">address</p>
                    </div>
                    <div class="col-sm-9">
                        <input type = "text" id="floatingInput" className="form-control" value={addresss} onChange={(e) => (setAddresss(e.target.value),validateAddress(e))} />                        
                        <span
                            style={
                                addresssError === ""
                                ? {
                                    fontFamily: "raleway",
                                    fontWeight: "600",
                                    color: "#FFFFFF",
                                    fontSize: "14px",
                                  }
                                : {
                                    fontFamily: "raleway",
                                    fontWeight: "400",
                                    color: "#E11B2F",
                                    fontSize: "14px",
                                  }
                            }
                          >
                            {addresssError}
                        </span>                                                                                        
                    </div>
                    </div>
                    <hr/>
                    <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">city</p>
                    </div>
                    <div class="col-sm-9">
                        <input type = "text" id="floatingInput" className="form-control" value={citys} onChange={(e) => (setCitys(e.target.value),validateCity(e))}/>                        
                        <span
                            style={
                                citysError === ""
                                ? {
                                    fontFamily: "raleway",
                                    fontWeight: "600",
                                    color: "#FFFFFF",
                                    fontSize: "14px",
                                  }
                                : {
                                    fontFamily: "raleway",
                                    fontWeight: "400",
                                    color: "#E11B2F",
                                    fontSize: "14px",
                                  }
                            }
                          >
                            {citysError}
                        </span>                                                                                        
                    </div>
                    </div>
                    <hr/>
                    <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">District</p>
                    </div>
                    <div class="col-sm-9">
                        <input type = "text" id="floatingInput" className="form-control" value={districts} onChange={(e) => (setDistricts(e.target.value),validateDistrict(e))}/>                        
                        <span
                            style={
                                districtsError === ""
                                ? {
                                    fontFamily: "raleway",
                                    fontWeight: "600",
                                    color: "#FFFFFF",
                                    fontSize: "14px",
                                  }
                                : {
                                    fontFamily: "raleway",
                                    fontWeight: "400",
                                    color: "#E11B2F",
                                    fontSize: "14px",
                                  }
                            }
                          >
                            {districtsError}
                        </span>                                                                                        
                    </div>
                    </div>  
                    <hr/>
                    <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">State</p>
                    </div>
                    <div class="col-sm-9">
                        <input type = "text" id="floatingInput" className="form-control" value={states} onChange={(e) => (setDetailsStates(e.target.value),validateState(e))}/>                        
                        <span
                            style={
                                statesError === ""
                                ? {
                                    fontFamily: "raleway",
                                    fontWeight: "600",
                                    color: "#FFFFFF",
                                    fontSize: "14px",
                                  }
                                : {
                                    fontFamily: "raleway",
                                    fontWeight: "400",
                                    color: "#E11B2F",
                                    fontSize: "14px",
                                  }
                            }
                          >
                            {statesError}
                        </span>                                                                                        
                    </div>
                    </div> 
                    <hr/>                             
                    <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">Pincode</p>
                    </div>
                    <div class="col-sm-9">
                        <input type = "number" id="floatingInput" className="form-control" value={pincodes} onChange={(e) => (setPincodes(e.target.value),validatePincode(e))}/>                        
                        <span
                            style={
                                pincodeerror === ""
                                ? {
                                    fontFamily: "raleway",
                                    fontWeight: "600",
                                    color: "#FFFFFF",
                                    fontSize: "14px",
                                  }
                                : {
                                    fontFamily: "raleway",
                                    fontWeight: "400",
                                    color: "#E11B2F",
                                    fontSize: "14px",
                                  }
                            }
                          >
                            {pincodeerror}
                        </span>                                                                                        
                    </div>
                    </div> 
                    <hr/> 
                    <div class="row">
                    <div class="col-sm-3">
                        <p class="mb-0">GST Number</p>
                    </div>
                    <div class="col-sm-9">
                        <input type = "text" id="floatingInput" className="form-control" value={gsts} onChange={(e) => setGsts(e.target.value)}/>                        
                    </div>
                    </div> 
                    <hr/>                                                                     
                    <div class="row">
                    <div class="col-sm-3">
                    </div>
                    <div class="col-sm-9">
                    <button
                          type="submit"
                          onClick={handleSubmit}
                          className="btn submit-bg px-4"
                          disabled={
                            names === "" ||
                            emails === "" ||
                            doornos === "" ||                            
                            addresss === "" ||                            
                            citys === "" ||                            
                            districts === "" ||                            
                            states === "" ||      
                            pincodes === "" ||                      
                            emailerror !== "" 
                              ? true
                              : false
                          }
                        >
                          Submit
                        </button>
                    </div>
                    </div>                                                  
                    
                 </div>                                     
            </div>
            </div>         
        </div>
     </div>
     </div>
     <div id="snackbar" style={{"background-color": "#333"}}></div>     
      <Footer></Footer>
    </React.Fragment>
  );
};

export default ProfilePage;
