import React, { useEffect,useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import db from "../../components/Firebase";
import useDocumentTitle from '../../useDocumentTitle';

const EarningPage = () => {  
    useDocumentTitle('Unobilabs - Orders');  
    let [name, setName] = useState(localStorage.getItem('name'));      
    let [phone, setPhone] = useState(localStorage.getItem('phone'));                  
    let [email, setEmail] = useState(localStorage.getItem('email'));      
    let [profile, setProfile] = useState(localStorage.getItem('profile'));        
    let [orders, setOrders] = useState([]);
    let [earnings, setEarnings] = useState(0); 
    const onTop = () => {
        window.scrollTo(0, 0);
    };
    const gotoOrders = () => {
        window.location.href = '/earnings';
    }    
    const fetchData = async ()  => {
        document.getElementById("loader").style.display = "block";                
        let orde = [];
        await db.collection("unobi_company_users").where("com_doc_id","==",localStorage.getItem("id")).get().then((querySnapshot) => {             
          querySnapshot.forEach(element => {
            let data = element.data();
            setEarnings(data['store_credit_total']);
            let reason = '';
            let coins = '';
            let timestamp = '';
            for(let i = 0; i < data['store_credit'].length; i++){
                let ts = data['store_credit'][i];
                reason = data['store_credit_details'][ts]['h_reason'];
                coins = data['store_credit_details'][ts]['h_coin'];                
                timestamp = ts;                
                orde.push({reason,coins,timestamp});
            }
          });
        });  
        setOrders(orde);
        document.getElementById("loader").style.display = "none";                        
    }   
    const logout = () => {
        localStorage.clear();        
        window.location.reload();
    }    
    const viewBill = (id) => {
        window.location.href = '/bill/' + id;
    }
    useEffect(() => {
        onTop();
        fetchData()
    }, []);
  return (
    <React.Fragment>
      <Header name="EarningPage"></Header>
      <div className="loader" id="loader" style={{ display: "none" }}></div>                  
      <section
        className="features-hero mt-5"
        style={{ "padding-top":"25px",
          backgroundImage: `url("../assets/images/banners/dashboard_banner.jpg")`,
        }}
      >
        <div className="container py-5">
          <div className="row py-3">
            <div className="col-12 text-center">
            <p className="feature-hero-head">Orders</p>
              <p className="feature-hero-sub-head">{localStorage.getItem("name")}</p>
            </div>
          </div>
        </div>
      </section>      

      <div className="container">
      <div class="row">
        <div class="col-lg-4">
            <div class="card mb-4">
            <div class="card-body text-center">
                <img src={"data:image/jpeg;base64," + profile} alt="avatar"
                class="rounded-circle img-fluid" style={{"width": "140px","height":"140px"}}/>
                <h5 class="my-3">{name}</h5>
                <p class="text-muted mb-1">{phone}</p>
                <p class="text-muted mb-4">{email}</p>
                <div class="d-flex justify-content-center mb-2">
                <button type="button" onClick={gotoOrders} class="btn btn-primary" style={{"width": "140px","background-color": "#373091!important"}}>Uno Coins {earnings}</button>
{/*                <button type="button" onClick={logout} class="btn btn-outline-primary ms-1" style={{"width" :"140px","border-color": "#373091!important","color": "#373091!important"}}>Logout</button> */}
                </div>
            </div>
            </div>
        
        </div>
        {
          earnings > 0 ? (
            <div class="col-md-8 col-xl-8 col-lg-8">
            {
                          orders.map((docs) => (
                            <div class="card shadow-0 border rounded-3 my-2">
                            <div class="card-body">
                                <div class="row">
                                <div class="col-md-8 col-lg-8 col-xl-8">
                                    <h5 style={{"marginTop":"10px"}}>{docs['reason']}</h5>
                                </div>
                                <div class="col-md-4 col-lg-4 col-xl-4 border-sm-start-none border-start justify-content-between">
                                    <div class="d-flex flex-row align-items-center mb-1">
                                            <h4 class="mb-1 me-1">+ {docs['coins']}</h4>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        ))
                    }
            </div>            
          ) : (
            <div class="col-md-8 col-xl-8 col-lg-8">
              <center><p>No activities available</p></center>
            </div>            
          )
        }
     </div>
     </div>
     <div id="snackbar" style={{"background-color": "#333"}}></div>     
      <Footer></Footer>
    </React.Fragment>
  );
};

export default EarningPage;
