import React, { useEffect,useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import db from "../../components/Firebase";
import validator from "validator";
import useDocumentTitle from '../../useDocumentTitle';
import { useParams } from "react-router-dom";

const BlogDetails = () => {  
  let docid = useParams();
  let [empty, setEmpty] = useState();
  let [title, setTitle] = useState();
  let [date, setDate] = useState();
  let [image, setImage] = useState();
    const onTop = () => {
        window.scrollTo(0, 0);
    };
    const fetchBlogDetails = async () => {
        document.getElementById("loader").style.display = "block";    
        await db.collection("unobilabs_blogs").where("b_title","==",docid.title).get().then((querySnapshot) => {      
            if(querySnapshot.empty){
                setEmpty(true);
            }   
            else{
                querySnapshot.forEach(element => {
                    setEmpty(false);                    
                    var data = element.data();
                    setTitle(data['b_title']);
                    let timestamp_int_convert = parseFloat(data['b_timestamp']);
                    let date = new Date(timestamp_int_convert*1000);
                    var date_creation = date.toLocaleDateString("en-US");                                            
                    setDate(date_creation);
                    setImage(data['b_image']);
                    document.getElementById("desc").innerHTML = data['b_detailed_desc'];
                });      
            }    
        });
        document.getElementById("loader").style.display = "none";            
    }
    useEffect(() => {
        onTop();
        fetchBlogDetails();
    }, []);
  return (
    <React.Fragment>
      <Header></Header>
      <div className="loader" id="loader" style={{ display: "none" }}></div>            
      <section
        className="features-hero mt-5"
        style={{"padding-top":"25px",
          backgroundImage: `url("../assets/images/banners/aboutus_banner.jpg")`,
        }}
      >
        <div className="py-5" style={{"margin-top": "92px"}}> 
          <div className="row py-3">
            <div className="col-12 text-center">
            <p className="feature-hero-head"></p>
              <p className="feature-hero-sub-head">{title}</p>
            </div>
          </div>
        </div>
      </section>            
      <div class="content ">    
        <div class="container ">
        <div class="row"  style={{"justify-content":"center"}}>
            <div class="col-md-6 mr-auto">
                <center>
            <img id="image" src={"data:image/jpeg;base64," + image} style={{"border-radius":"10px"}} alt={title} loading="crazy" />
            </center>
            </div>

            <div class="col-md-6 mr-auto">
            <br/> <br/> 
            <h2>{title}</h2>
            <p class="mb-5" id="desc" style={{"margin-top":"20px","color":"grey"}}></p>           
            </div>
        </div>

        
        
        </div> </div>
     <div id="snackbar" style={{"background-color": "#333"}}></div>     
      <Footer></Footer>
    </React.Fragment>
  );
};

export default BlogDetails;
