import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

const footer = (props) => {
  return (
    <div className="footer mt-5 footer_bg">
    <hr className="hr" />
    <div className="container">
      <div className="row py-4 mb-2">
        <div className="col-12 text-center text-md-start">
          <img
            src="/assets/images/logo/unobilabs_web_logo.svg"
            className="img-fluid"
            width={100}
            alt="logo"
          />
        </div>
      </div>
      {/* <div className="row py-4 mb-2">
        <div className="col-12 text-center text-md-start">
          <img
            src="../assets/img/logo/unobi.svg"
            className="img-fluid"
            alt="logo"
            width={100}
          />
        </div>
      </div> */}
      <div className="row">
        {/* <div className="col-md-3 text-center text-md-start pt-4 pt-md-0">
                    <img src="../assets/img/logo/unobi.svg" className='img-fluid' width={100} />
                    <p className='footer_head2 pt-5'>Follow Us On</p>

                </div> */}
        <div className="col-md-3 text-center text-md-start pt-4 pt-md-0">
          <p className="footer_head">Other Links</p>
          {/* <p className='footer_links'>YouTube Tutorial</p>
                    <p className='footer_links'>Blogs</p>
                    <p className='footer_links'>FAQs</p> */}
          <a href="/allproducts" className="footer_links">
            <p>Products</p>
          </a>                              
          <a href="/categories" className="footer_links">
            <p>Categories</p>
          </a>                                                            
          <a href="/blogs" className="footer_links">
            <p>Blogs</p>
          </a>                              
          <a href="/contactus" className="footer_links">
            <p>Contact Us</p>
          </a>
          <a href="/AboutUs" className="footer_links">
            <p>About Us</p>
          </a>
          <a href="/careers" className="footer_links">
            <p>Careers</p>
          </a>
          <a href="/terms-conditions" className="footer_links">
            <p>Terms and conditions</p>
          </a>
          <a href="/security-payments" className="footer_links">
            <p>Security and Payments</p>
          </a>
          <a href="/privacy-policy" className="footer_links">
            <p>Privacy policy</p>
          </a>          
        </div>
        <div className="col-md-3 text-center text-md-start pt-4 pt-md-0">
          <p className="footer_head">Follow Us On</p>

          <a
            className="social text-dark" target="_blank"
            href="https://www.instagram.com/unobilabs/"
          >
            <p>
              <i className="fa-brands fa-instagram-square">
                <span className="footer_links ps-2">Instagram</span>
              </i>
            </p>
          </a>

          <a
            className="social text-dark " target="_blank"
            href="https://www.facebook.com/unobilabs/"
          >
            <p>
              <i className="fa-brands fa-facebook">
                <span className="footer_links ps-2">Facebook</span>
              </i>
            </p>
          </a>
          <a
            className="social text-dark " target="_blank"
            href=""
          >
            <p>
              <i className="fa-brands fa-twitter">
                <span className="footer_links ps-2">Twitter</span>
              </i>
            </p>
          </a>
        </div>
        <div className="col-md-3 text-center text-md-start pt-4 pt-md-0">
          <p className="footer_head">Get in touch</p>
          <p>
            <i className="fa-solid fa-envelope-open">
              <a
                href="mailto:support@unobilabs.com"
                className="footer_links ps-2"
              >
                support@unobilabs.com
              </a>
            </i>
          </p>
          <p>
            <i className="fa-solid fa-phone">
              <a href="tel:91-636-960-6204" className="footer_links ps-2">
                +91 6369606204
              </a>
            </i>
          </p>
          <p>
            <i className="fa-brands fa-google-play">
              <a href="https://play.google.com/store/apps/details?id=com.unobi.app" className="footer_links ps-2">
                Get Unobilabs APK in playstore
              </a>
            </i>
          </p>
        </div>

        <div className="col-md-3 text-center text-md-start pt-4 pt-md-0">
          <p className="footer_head">Install App</p>
          <img src="/assets/images/logo/google-play.jpg" style={{"height":"45px"}} /> 
          <img src="/assets/images/logo/app-store.jpg" style={{"height":"45px"}} /> 
          
          <p>
          <p className="footer_head" style={{"margin-top":"5px","margin-bottom":"5px"}}>
          Secured Payment Gateways
          </p>
          <img src="/assets/images/logo/payment-method.png" style={{"height":"30px"}} /> 
          </p>
        </div>
      </div>
      <hr />
      <p className="footer_links text-center">
      &copy; Copyright 2022 by Unobi Labs. All rights reserved.
      </p>
    </div>
  </div>

    );
};

export default footer;
   
