import { v4 as uuidv4 } from 'uuid';


export let BrandData = [
    {
        id: uuidv4(),
        img_url : "/assets/images/brand/1.png",
        img_head: "Oscar"
    },
    {
        id: uuidv4(),
        img_url : "/assets/images/brand/2.png",
        img_head: "J Mitra"
    },    
    {
        id: uuidv4(),
        img_url : "/assets/images/brand/3.png",
        img_head: "Rapid Diagnostics"
    },    
    {
        id: uuidv4(),
        img_url : "/assets/images/brand/4.png",
        img_head: "Athenese Dx"
    },    
    {
        id: uuidv4(),
        img_url : "/assets/images/brand/5.png",
        img_head: "Inbios"
    },    
    {
        id: uuidv4(),
        img_url : "/assets/images/brand/6.png",
        img_head: "Alco"
    },    
    {
        id: uuidv4(),
        img_url : "/assets/images/brand/7.png",
        img_head: "Teco"
    },    
    {
        id: uuidv4(),
        img_url : "/assets/images/brand/8.png",
        img_head: "Alkor Bio"
    },    
    {
        id: uuidv4(),
        img_url : "/assets/images/brand/9.png",
        img_head: "Novatec"
    },    
    {
        id: uuidv4(),
        img_url : "/assets/images/brand/10.png",
        img_head: "Dia Pro"
    },    
    {
        id: uuidv4(),
        img_url : "/assets/images/brand/11.png",
        img_head: "Ldn"
    },    
    {
        id: uuidv4(),
        img_url : "/assets/images/brand/12.png",
        img_head: "Scimedx"
    },    
    {
        id: uuidv4(),
        img_url : "/assets/images/brand/13.png",
        img_head: "GB"
    },    
    {
        id: uuidv4(),
        img_url : "/assets/images/brand/14.png",
        img_head: "Savyan Diagnostics"
    },    
    {
        id: uuidv4(),
        img_url : "/assets/images/brand/15.png",
        img_head: "Diametra"
    },    
    {
        id: uuidv4(),
        img_url : "/assets/images/brand/16.png",
        img_head: "Hotgen"
    },    
    {
        id: uuidv4(),
        img_url : "/assets/images/brand/17.png",
        img_head: "Radim"
    },    
    {
        id: uuidv4(),
        img_url : "/assets/images/brand/18.png",
        img_head: "Bios"
    },    
    {
        id: uuidv4(),
        img_url : "/assets/images/brand/19.png",
        img_head: "CTK Biotech"
    },    
    {
        id: uuidv4(),
        img_url : "/assets/images/brand/20.png",
        img_head: "Ypsomed"
    },    
    {
        id: uuidv4(),
        img_url : "/assets/images/brand/21.png",
        img_head: "Titan media"
    },    
    {
        id: uuidv4(),
        img_url : "/assets/images/brand/22.png",
        img_head: "Gem"
    },    
    {
        id: uuidv4(),
        img_url : "/assets/images/brand/23.png",
        img_head: "Vivyan lifesciences"
    },    
    {
        id: uuidv4(),
        img_url : "/assets/images/brand/24.png",
        img_head: "Vibles"
    },    
]