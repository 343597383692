import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from './pages/HomePage/HomePage';
import ProductsPage from './pages/Products/ProductsPage';
import ProductDetails from './pages/ProductDetails/ProductDetails';
import CartPage from './pages/CartPage/CartPage';
import OrderSummary from './pages/SummaryPage/OrderSummary';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import OrdersPage from './pages/OrdersPage/OrdersPage';
import Auth from './pages/Auth';
import OrderDetails from './pages/OrderDetails/OrderDetails';
import ContactUsPage from './pages/ContactUs/ContactUs';
import BlogPage from './pages/Blog/BlogPage';
import TermsConditions from './pages/TermsConditions/TermsConditions';
import CareersPage from './pages/CareersPage/CareersPage';
import AboutUs from './pages/AboutUs/AboutUs';
import SecurityPayments from './pages/TermsConditions/Security-Payments';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import SearchResult from './pages/SearchResult/SearchResult';
import PageNotFound from './pages/404/PageNotFound';
import CategoryPage from './pages/CategoryPage/CategoryPage';
import EarningPage from './pages/EarningPage/EarningPage';
import AllProductsPage from './pages/AllProductsPage/AllProductsPage';
import BlogDetails from './pages/BlogDetails/BlogDetails';
import BrandProductsPage from './pages/BrandProductsPage/BrandProductsPage';

function App() {
  let session = localStorage.getItem("id");
  return (
    <React.Fragment>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/categories" element={<CategoryPage />} />          
          <Route path='/category/:id' element={<ProductsPage/>}/>
          <Route path='/product/:id' element={<ProductDetails/>}/>
          <Route path='/product/:id/:name' element={<ProductDetails/>}/>          
          <Route path='/mycart' element={ session ? (<CartPage/>) : (<Auth />)}/>          
          <Route path='/profile' element={ session ? (<ProfilePage/>) : (<Auth />)}/>                    
          <Route path='/orders' element={ session ? (<OrdersPage/>) : (<Auth />)}/>                              
          <Route path='/bill/:id' element={ session ? (<OrderDetails/>) : (<Auth />)}/>                                        
          <Route path='/order-summary/:id' element={ session ? (<OrderSummary/>) : (<Auth/>)}/>  
          <Route path='/earnings' element={ session ? (<EarningPage/>) : (<Auth />)}/>                            
          <Route path='/contactus' element={<ContactUsPage/>}/>                    
          <Route path='/terms-conditions' element={<TermsConditions/>}/>    
          <Route path='/security-payments' element={<SecurityPayments/>}/> 
          <Route path='/careers' element={<CareersPage/>}/>         
          <Route path='/AboutUs' element={<AboutUs/>}/>                
          <Route path='/blogs' element={<BlogPage/>}/>     
          <Route path='/blog-details/:title' element={<BlogDetails/>}/>                               
          <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>     
          <Route path='/search-result/:id' element={<SearchResult/>}/>
          <Route path='/allproducts' element={<AllProductsPage/>}/>    
          <Route path='/brand/:id' element={<BrandProductsPage/>}/>              
          <Route path="*" element={<PageNotFound />} />                                    
        </Routes>
      </Router>
    </React.Fragment>
  );
}

export default App;
