import React, { useEffect,useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import db from "../../components/Firebase";
import validator from "validator";
import useDocumentTitle from '../../useDocumentTitle';

const CareersPage = () => {  
    let [name, setName] = useState("");
    let [email, setEmail] = useState("");    
    let [mobile, setMobile] = useState("");
    let [file, setFile] = useState("");
    let [nameError, setNameError] = useState("");
    let [emailError, setEmailError] = useState("");
    let [phoneError, setPhoneError] = useState("");            
    let [fileError, setFileError] = useState("");
    const onTop = () => {
        window.scrollTo(0, 0);
    };
    const validateEmail = (e) => {
        var email = e.target.value;
        if (validator.isEmail(email)) {
          setEmailError("");
        } else {
          setEmailError("Please provide valid Email.");
        }
      };    
      const showToast = (types,text) => {
        if(types == "failed")
        {
          var x = document.getElementById("snackbar");
          x.className = "show";
          x.style.backgroundColor = "#de3d3d";      
          setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);            
          document.getElementById("snackbar").innerText = text;
        }
        else
        {
          var x = document.getElementById("snackbar");
          x.className = "show";
          x.style.backgroundColor = "#21b446";
          setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);            
          document.getElementById("snackbar").innerText = text;
//          window.location.reload();
        }
      }        
      const validatePhone = (e) => {
        var phone = e.target.value;
    
        if (validator.isMobilePhone(phone) && phone.length === 10) {
          setPhoneError("");
        } else {
          setPhoneError("Enter valid Phone Number");
        }
      };        
      const validateName = (e) => {
        var name = e.target.value;
        if (name === '') {
          setNameError("Name is required");
        } else {
          setNameError("");
        }        
      }      
      const validateFile = (e) => {
        var file = e.target.files[0];
        if (file === '') {
          setFileError("File is required");
        } else {
          let fileName = "";          
          if(file.type == "application/pdf" && file.size <= 1e6){
            let fileToLoad = e.target.files[0];
            fileName = fileToLoad.name;
            let fileReader = new FileReader();
            fileReader.onload = function(fileLoadedEvent) {
                file = fileLoadedEvent.target.result;
                setFile(file);
            };
            fileReader.readAsDataURL(fileToLoad);            
            setFileError("");
          }
          else{
            setFileError("Upload pdf with 1 mb");            
          }
        }        
      }            
    const submitData = async () => {
        let timestamap = new Date().getTime();     
        let string = timestamap + '';   
        if(name != '' && email != '' && mobile != '' && file != '')
        {
          await db.collection("unobilabs_carriers").doc(string).set({
              "c_name" : name,
              "c_email" : email,
              "c_phone" :  mobile,
              "c_file" : file,
              "c_id" : timestamap,
          },{merge : true}).then(snap => {
              showToast("success", "Submitted Successfully");
              setName("");
              setEmail("");
              setMobile("");
              setFile("");    
          }).catch(err => {
              showToast("failed", "Something wrong");
          })
        }
        else{
          showToast("failed","Please fill all details");            
          if(name == ""){
            setNameError("Name is required");
          }          
          if(email == ""){
            setEmailError("Email is required");
          }                    
          if(mobile == ""){
            setPhoneError("Mobile no is required");
          }                              
          if(file == ""){
            setFileError("File is required");
          }                                        
        }
    }
    useEffect(() => {
        onTop();
    }, []);
  return (
    <React.Fragment>
      <Header></Header>
      <div className="loader" id="loader" style={{ display: "none" }}></div>            
      <section
        className="features-hero mt-5"
        style={{"padding-top":"25px",
          backgroundImage: `url("../assets/images/banners/career_banner.jpg")`,
        }}
      >
        <div className="py-5" style={{"margin-top": "92px"}}> 
          <div className="row py-3">
            <div className="col-12 text-center">
            <p className="feature-hero-head"></p>
              <p className="feature-hero-sub-head">Careers</p>
            </div>
          </div>
        </div>
      </section>            
      <div class="content ">    
        <div class="container card">
        <div class="row"  style={{"justify-content":"center"}}>
            <div class="col-md-5 mr-auto">
            <h2 style={{"padding-bottom":"1.2rem"}}>New Openings</h2>
            <ul>
              
              <li>Lab Technicians</li>
              <li>Sales Executive</li>
              <li>Business Development Opportunities</li> 
            </ul>

           
            </div>

            <div class="col-md-6" style={{"padding-top":"12px"}}>
                <div class="row">
                
                <div class="col-md-12 form-group">
                    <label for="name" class="col-form-label">Name</label>
                    <input type="text" onChange={(e) => (setName(e.target.value),validateName(e))} class="form-control" value={name} name="name" id="name"/>
                    <span
                            style={
                              nameError === ""
                                ? {
                                    fontFamily: "raleway",
                                    fontWeight: "600",
                                    color: "#FFFFFF",
                                    fontSize: "14px",
                                  }
                                : {
                                    fontFamily: "raleway",
                                    fontWeight: "400",
                                    color: "#E11B2F",
                                    fontSize: "14px",
                                  }
                            }
                          >
                            {nameError}
                    </span>                                        
                </div>
                </div>
                <div class="row">
                <div class="col-md-12 form-group">
                    <label for="email" class="col-form-label">Email</label>
                    <input type="text" onChange={(e) => (setEmail(e.target.value), validateEmail(e))} class="form-control" value={email} name="email" id="email"/>
                    <span
                            style={
                              emailError === ""
                                ? {
                                    fontFamily: "raleway",
                                    fontWeight: "600",
                                    color: "#FFFFFF",
                                    fontSize: "14px",
                                  }
                                : {
                                    fontFamily: "raleway",
                                    fontWeight: "400",
                                    color: "#E11B2F",
                                    fontSize: "14px",
                                  }
                            }
                          >
                            {emailError}
                    </span>                    
                </div>
                </div>

                <div class="row">
                <div class="col-md-12 form-group">
                    <label for="email" class="col-form-label">Mobile</label>
                    <input type="text" onChange={(e) => (setMobile(e.target.value), validatePhone(e))} class="form-control" value={mobile} name="email" id="email"/>
                    <span
                            style={
                              phoneError === ""
                                ? {
                                    fontFamily: "raleway",
                                    fontWeight: "600",
                                    color: "#FFFFFF",
                                    fontSize: "14px",
                                  }
                                : {
                                    fontFamily: "raleway",
                                    fontWeight: "400",
                                    color: "#E11B2F",
                                    fontSize: "14px",
                                  }
                            }
                          >
                            {phoneError}
                      </span>                    
                </div>
                </div>
                <div class="row">
                <div class="col-md-12 form-group">
                    <label for="email" class="col-form-label">Resume</label>
                    <input type="file" onChange={(event) => {
                      setFile(event.target.files[0]);
                      validateFile(event);
                    }} class="form-control" name="email" id="email"/>
                    <span
                            style={
                              fileError === ""
                                ? {
                                    fontFamily: "raleway",
                                    fontWeight: "600",
                                    color: "#FFFFFF",
                                    fontSize: "14px",
                                  }
                                : {
                                    fontFamily: "raleway",
                                    fontWeight: "400",
                                    color: "#E11B2F",
                                    fontSize: "14px",
                                  }
                            }
                          >
                            {fileError}
                          </span>                    
                </div>
                </div>
                <div class="row">
                <div class="col-md-12"  style={{"padding-top":"12px"}}>
                    <input type="button" onClick={submitData} value="Send Message" class="btn btn-primary rounded-0 py-2 px-4"
//                          disabled={
//                            name === "" ||
//                            msg === "" ||
//                            email === "" ||
//                            emailError !== "" 
//                              ? true
//                              : false
//                          }                    
                    />
                    <span class="submitting"></span>
                </div>
                </div>
    
            </div>
        </div>

        
        
        </div> </div>
     <div id="snackbar" style={{"background-color": "#333"}}></div>     
      <Footer></Footer>
    </React.Fragment>
  );
};

export default CareersPage;
