import React from "react";
import "./404.css";

const PageNotFound = () => {
  return (
    <div className="container my-auto mt-5">
        <div className="row">
            <div className="col-12 text-center">
                <img src="/assets/images/banners/unobi_labs_404.png" className="img-fluid" alt="404"/>
            </div>
        </div>
    </div>
    );
};

export default PageNotFound;
