import React, { useEffect,useState } from "react";
import { useLocation,useParams } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import db from "../../components/Firebase";
import "./OrderSummary.css";
import useDocumentTitle from '../../useDocumentTitle';

const OrderSummary = () => {  
  useDocumentTitle('Unobilabs - Order Summary');  
  const [blogs, setBlogs] = useState([]);    
  const [pro, setProducts] = useState([]); 
  let docid = useParams();
  
  let date = docid.id.substring(7);
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  const fetchBlogs = async () => {
    document.getElementById("loader").style.display = "block";                             
    await db.collection("unobi_bills").where("bill_number","==",docid.id).get().then((querySnapshot) => {             
      var details = [];
      var items = [];
      querySnapshot.forEach(function(doc) {
        let data = doc.data();
        let storename = data['b_storename'];
        let storeaddress = data['b_storeaddress1'];
        let billdate = data['b_number'];
        let b_sgst_total = data['b_sgst_total'];
        let b_cgst_total = data['b_cgst_total'];
        let b_gst_total = data['b_gst_total'];
        let overalltotal = data['b_total'];
        let vv_total = data['b_payments']['b_total'];
        let ov_mrp = data['b_mrp'];
        let pay_type = data['b_payments']['p_type'];
        let p_transaction_id = data['b_payments']['p_transaction_id'];
        let payable_total = data['b_payments']['payable_total'];
        let percentage = data['b_payments']['percentage'];
        let coins_used = data['b_payments']['u_coins'];
        let total_coins = data['b_payments']['total_coins'];
        let name = data['b_shipping']['s_name'];
        let bilnumber = data['b_number']
        let billing = data['b_billing'];
        let shipping = data['b_shipping'];
        let b_user_gst = data['b_user_gst'];
        for(let i = 0; i < data['b_products'].length; i++)
        {
          let barcode = data['b_products'][i];
          if(data['products'][barcode]['p_qty'] > 0)
          {
            let p_name = data['products'][barcode]['p_name'];
            let p_qty = data['products'][barcode]['p_qty'];
            let salesprice = data['products'][barcode]['p_salesprice'];
            let total = data['products'][barcode]['p_total'];
            let mrp = data['products'][barcode]['p_mrp'];
            let gst = data['products'][barcode]['p_gst'];
            let cgst = data['products'][barcode]['p_cgst'];
            let sgst = data['products'][barcode]['p_sgst'];
            let pcategory = data['products'][barcode]['p_category'];
            let pimg = data['products'][barcode]['p_img'];
            let pdesc = data['products'][barcode]['p_desc'];    
            items.push({p_name,p_qty,salesprice,total,mrp,gst,barcode,cgst,sgst,pcategory,pimg,pdesc});                
          }
        }
        details.push({ storename,storeaddress,billdate,b_sgst_total,b_cgst_total,b_gst_total,overalltotal,ov_mrp,pay_type,p_transaction_id,payable_total,percentage,name,bilnumber,vv_total,billing,shipping,coins_used,total_coins,b_user_gst });
      });
      setProducts(items);
      setBlogs(details);
      document.getElementById("loader").style.display = "none";                               
  })   
  };  
  useEffect(() => {
    onTop();
    fetchBlogs();    
  }, []);
  return (
    <React.Fragment>
      <Header></Header>
      <div className="loader" id="loader" style={{ display: "none" }}></div>                  
 
    <section class="section-b-space cart-section order-details-table" style={{"margin-top":"90px","margin": "0"}}>
        <div class="container">
        <div class="container" style={{"width":"100%","padding-top": "100px","background-color":"#f7f7f7"}}>
        <div class="check" >
                    <i class="fa fa-certificate rotate" style={{"font-size": "85px","text-align":"center","color": "#373091"}}></i>
                </div>
                <i class="fa fa-check circled" style={{"font-size": "30px", "text-align":"center","width": "100%","color": "white","padding-top":"69px"}}></i>
                <div class="px-4 py-5"><h5 class="fw-bold mb-0" style={{"text-align":"center"}}>Thanks for your Order, <span style={{"color": "#373091"}}>{localStorage.getItem("name")}</span>!</h5>
                <p style={{"text-align": "center","width":"100%","padding-top": "30px"}}>Thanks so much for your order! I hope you enjoy your new purchase! Please reach out to me at support@unobilabs.com if you need anything.</p>
                </div></div>
            <div class="title title1 title-effect mb-1 title-left">
                <h2 class="mb-3" style={{"padding-top":"20px"}}>Order Summary</h2>
            </div>
            {
                blogs.map((doc) => (                                                    
            <div class="row g-4">
                  <div class="col-md-6">
                      <div class="col-sm-12 table-responsive">
                          <table class="table cart-table table-borderless">
                              <tbody>
                              {
                              pro.map((docs) => (                                                        
                                  <tr class="table-order">
                                      <td style={{"width":"105px"}}>
                                          <a href="javascript:void(0)">
                                              <img src={"https://unobilabs.com" + docs['pimg'] }
                                                  class="img-fluid blur-up lazyload" alt=""/>
                                          </a>
                                      </td>
                                      <td>
                                          <p style={{"color":"#777777","margin-bottom":"3px","font-size":"12px"}}>Product Name</p>
                                          <h5 style={{"font-size":"16px"}}>{docs['p_name']}</h5>
                                      </td>
                                      <td>
                                          <p  style={{"color":"#777777","margin-bottom":"3px","font-size":"12px"}}>Quantity</p>
                                          <h5 style={{"font-size":"16px"}}>{docs['p_qty']} * {docs['salesprice']}</h5>
                                      </td>
                                      <td>
                                          <p  style={{"color":"#777777","margin-bottom":"3px","font-size":"12px"}}>Price</p>
                                          <h5 style={{"font-size":"16px"}}>&#8377;{docs['total']}</h5>
                                      </td>
                                  </tr>
                              ))
                              }               
                              </tbody>
                              <tfoot>
                                  <tr class="table-order">
                                      <td colspan="3">
                                          <h6 style={{"color":"#7e7e7e","margin-bottom":"0px"}}>CGST :</h6>
                                      </td>
                                      <td>
                                          <h6  style={{"margin-bottom":"0px"}}>&#8377;{doc['b_cgst_total']}</h6>
                                      </td>
                                  </tr>

                                  <tr class="table-order">
                                      <td colspan="3">
                                      <h6 style={{"color":"#7e7e7e","margin-bottom":"0px"}}>SGST :</h6>
                                  
                                      </td>
                                      <td>
                                      <h6  style={{"margin-bottom":"0px"}}>&#8377;{doc['b_sgst_total']}</h6>
                                      </td>
                                  </tr>

                                  <tr class="table-order">
                                      <td colspan="3">
                                      <h6 style={{"color":"#7e7e7e","margin-bottom":"0px"}}>Total Tax(Included) :</h6>
                                          
                                      </td>
                                      <td>
                                      <h6  style={{"margin-bottom":"0px"}}>&#8377;{doc['b_gst_total']}</h6>
                                      </td>
                                  </tr>
                                  <tr class="table-order">
                                      <td colspan="3">
                                      <h6 style={{"color":"#7e7e7e","margin-bottom":"0px"}}>Total :</h6>
                                          
                                      </td>
                                      <td>
                                      <h6  style={{"margin-bottom":"0px"}}>&#8377;{doc['vv_total']}</h6>
                                      </td>
                                  </tr>                                  

                                  <tr class="table-order">
                                      <td colspan="3">
                                      <h6 style={{"color":"#7e7e7e","margin-bottom":"0px"}}>Discount :</h6>
                                  
                                      </td>
                                      <td>
                                      <h6  style={{"margin-bottom":"0px"}}>({doc['percentage']}% off)</h6>
                                      </td>
                                  </tr>
                                  {
                                    doc['coins_used'] == "Yes" ? (
                                        <tr class="table-order">
                                        <td colspan="3">
                                        <h6 style={{"color":"#7e7e7e","margin-bottom":"0px"}}>Coins Used :</h6>
                                    
                                        </td>
                                        <td>
                                        <h6  style={{"margin-bottom":"0px"}}> -{doc['total_coins']}</h6>
                                        </td>
                                    </tr>  
                                    ) : (
                                        <span></span>
                                    )
                                  }
                                  <tr class="table-order">
                                      <td colspan="3">
                                      <h6 style={{"color":"#7e7e7e","margin-bottom":"0px"}}>Shipping charge:</h6>
                                  
                                      </td>
                                      <td>
                                      <h6  style={{"margin-bottom":"0px"}}>free</h6>
                                      </td>
                                  </tr>

                                

                                  <tr class="table-order">
                                      <td colspan="3">
                                          <h4 class="theme-color fw-bold">Total Price :</h4>
                                      </td>
                                      <td>
                                          <h4 class="theme-color fw-bold">&#8377;{doc['payable_total']}</h4>
                                      </td>
                                  </tr>
                              </tfoot>
                          </table>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="order-success" >
                          <div class="row g-4">
                              <div class="col-12">
                                  <h4>summery</h4>
                                  <ul class="order-details" >
                                      <li>Order ID: {docid.id.substring(7)}</li>
                                      <li>Order Total: &#8377;{doc['payable_total']}</li>
                                      <li>GST Number: {doc['b_user_gst']}</li>
                                      <li>Alternate Number: {doc['shipping']['b_alt_phone']}</li>                                                                            
                                  </ul>
                              </div>

                              <div class="col-12">
                                  <div class="payment-mode">
                                  <h4>Billing address</h4>
                                  <ul class="order-details">
                                      <li>{doc['billing']['b_name']},</li>                                    
                                      <li>{doc['billing']['b_door']},{doc['billing']['b_address']}</li>
                                      <li>{doc['billing']['b_district']}, {doc['billing']['b_city']},{doc['billing']['b_pincode']}</li>
                                      <li>{doc['billing']['b_state']}, {doc['billing']['b_country']}</li>
                                  </ul>
                                  </div>
                              </div>
                              <div class="col-md-12">
                                  <div class="delivery-sec">
                                  <h4>shipping address</h4>
                                  <ul class="order-details">
                                      <li>{doc['shipping']['s_name']},</li>                                    
                                      <li>{doc['shipping']['s_door']},{doc['shipping']['s_address']}</li>
                                      <li>{doc['shipping']['s_district']}, {doc['shipping']['s_city']},{doc['shipping']['s_pincode']}</li>
                                      <li>{doc['shipping']['s_state']}, {doc['shipping']['s_country']}</li>                                    
                                  </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
            </div>
                  ))
                }                               
        </div>
    </section>    
      <Footer></Footer>
    </React.Fragment>
  );
};

export default OrderSummary;
