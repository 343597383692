import React, { useEffect,useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import db from "../../components/Firebase";
import "./BlogPage.css";
import validator from "validator";
import useDocumentTitle from '../../useDocumentTitle';

const BlogPage = () => {  
  let [blogs, setBlogs] = useState([]);
  let [empty, setEmpty] = useState(false);
    const onTop = () => {
        window.scrollTo(0, 0);
    };
    const fetchBlogs = async () => {
        document.getElementById("loader").style.display = "block";    
        await db.collection("unobilabs_blogs").get().then((querySnapshot) => {      
            if(querySnapshot.empty){
                setEmpty(true);
            }   
            else{
                querySnapshot.forEach(element => {
                    setEmpty(false);                    
                    var data = element.data();
                    setBlogs(arr => [...arr , data]);                            
                });      
            }    
        });
        document.getElementById("loader").style.display = "none";            
    }     
    const blogDetails = async (title) => {
        window.location.href = 'blog-details/' + title;
    }
    useEffect(() => {
        onTop();
        fetchBlogs();
    }, []);
  return (
    <React.Fragment>
      <Header></Header>
      <div className="loader" id="loader" style={{ display: "none" }}></div>            
      <section
        className="features-hero mt-5"
        style={{"padding-top":"25px",
          backgroundImage: `url("../assets/images/banners/aboutus_banner.jpg")`,
        }}
      >
        <div className="py-5" style={{"margin-top": "92px"}}> 
          <div className="row py-3">
            <div className="col-12 text-center">
            <p className="feature-hero-head"></p>
              <p className="feature-hero-sub-head">Blog page</p>
            </div>
          </div>
        </div>
      </section>            
      <div class="content ">    
          <div className="container">
            <div class="row">
            {
                empty ? (
                    <center><h3>No Blogs Found</h3></center>
                ) :(
                    blogs && blogs.map(blog=>{
                        let timestamp_int_convert = parseFloat(blog.b_timestamp);
                        let date = new Date(timestamp_int_convert*1000);
                        var date_creation = date.toLocaleDateString("en-US");                        
                        return(
                            <div class="col-md-4">
                                <div class="content-blog blog-grid">
                                    <div class="inner">
                                        <div class="thumbnail">
                                            <a href="#" style={{"text-decoration":"none"}}>
                                                <img src={"data:image/jpeg;base64," + blog.b_image} alt={blog.b_title} loading="crazy"/>
                                            </a>
                                            <hr/>
                                            <div class="blog-category">
                                                <a href="#" style={{"text-decoration":"none","color":"#b4c3be"}}>{date_creation}</a>
                                            </div>
                                        </div>
                                        <div class="content">
                                            <h5 class="title"><a href="#" style={{"text-decoration":"none","color":"#303791"}}>{blog.b_title}</a></h5>
        
                                            <div class="read-more-btn">
                                                <a class="axil-btn zoom-new" onClick={() => blogDetails(blog.b_title)} style={{"text-decoration":"none"}}>Read More  </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })    
                )
            }    
            </div>  
        </div>
    </div>
     <div id="snackbar" style={{"background-color": "#333"}}></div>     
      <Footer></Footer>
    </React.Fragment>
  );
};

export default BlogPage;
