import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import useDocumentTitle from '../../useDocumentTitle';

const Securitypayments = () => {  
    const onTop = () => {
        window.scrollTo(0, 0);
    };
    useEffect(() => {
        onTop();
    }, []);
  return (
    <React.Fragment>
      <Header></Header>
      <div className="loader" id="loader" style={{ display: "none" }}></div>                  
      <section
        className="features-hero mt-5"
        style={{ "padding-top":"25px",
          backgroundImage: `url("./assets/images/banners/secure_payment_banner.jpg")`,
        }}
      >
        <div className="container py-5">
          <div className="row py-3">
            <div className="col-12 text-center">
            <p className="feature-hero-head">Security and Payments</p>
            </div>
          </div>
        </div>
      </section>      
      <div style={{"padding":"4rem"}}>
      <h2><strong>Security and Payments</strong></h2>
      <p><strong>Safe and Secure Shopping</strong></p>
<p><strong>Is it safe to use my credit/debit card on Unobilabs?</strong></p>
<p>Your online transaction on Unobilabs is secure with the highest levels of transaction security currently available on the Internet. Unobilabs uses 256-bit encryption technology to protect your card information while securely transmitting it to the respective banks for payment processing. All credit card and debit card payments on Unobilabs are processed through secure and trusted payment gateways managed by leading banks. Banks now use the 3D Secure password service for online transactions, providing an additional layer of security through identity verification.</p>
<p><strong>Does Unobilabs store my credit/debit card infomation?</strong></p>
<p>Unobilabs stores the first 6 and last 4 digits of your card number in a secure and encrypted manner. The first 6 digits (also known as the Bank Identification Number) are used to identify the bank name and country where your card was issued. The first 6 and last 4 digits are together used for fraud detection and prevention purposes.</p>
<p><strong>Payment Options</strong></p>
<p><strong>What credit/debit cards are accepted on Unobilabs?</strong></p>
<p>We accept VISA, MasterCard, Maestro, Rupay, American Express, Diner&rsquo;s Club and Discover credit/debit cards.</p>
<p><strong>Do you accept payment made by credit/debit cards issued in other countries?</strong></p>
<p>Yes! We accept VISA, MasterCard, Maestro, American Express credit/debit cards issued by banks in India Please note that we do not accept internationally issued credit/debit cards for eGV payments/top-ups.</p>
<p><strong>What other payment options are available on Unobilabs?</strong></p>
<p>Apart from Credit and Debit Cards, we accept payments by Internet Banking (covering 44 banks), Cash-on-Delivery, PayTM UPI and PayTM wallet.</p>
<p><strong>Privacy Policy</strong></p>
<p>Unobilabs.com respects your privacy and is committed to protecting it. For more details, please see our Privacy Policy</p>
<p><strong>Contact Us</strong></p>
<p>Couldn&rsquo;t find the information you need? Please Contact Us</p>
 </div>
      <Footer></Footer>
    </React.Fragment>
  );
};

export default Securitypayments;