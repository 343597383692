import React, { useEffect,useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import db from "../../components/Firebase";
import validator from "validator";
import useDocumentTitle from '../../useDocumentTitle';

const ContactUsPage = () => {  
    let [name, setName] = useState("");
    let [email, setEmail] = useState("");    
    let [msg, setMsg] = useState("");    
    let [emailError, setEmailError] = useState("");
    const onTop = () => {
        window.scrollTo(0, 0);
    };
    const validateEmail = (e) => {
        var email = e.target.value;
        if (validator.isEmail(email)) {
          setEmailError("");
        } else {
          setEmailError("Please provide valid Email.");
        }
      };    
      const showToast = (types,text) => {
        if(types == "failed")
        {
          var x = document.getElementById("snackbar");
          x.className = "show";
          x.style.backgroundColor = "#de3d3d";      
          setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);            
          document.getElementById("snackbar").innerText = text;
        }
        else
        {
          var x = document.getElementById("snackbar");
          x.className = "show";
          x.style.backgroundColor = "#21b446";
          setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);            
          document.getElementById("snackbar").innerText = text;
          window.location.reload();
        }
      }        
    const submitData = async () => {
        let timestamap = new Date().getTime();     
        let string = timestamap + '';   
        await db.collection("unobi_contactus").doc(string).set({
            "c_name" : name,
            "c_email" : email,
            "c_msg" : msg,
            "time" :  timestamap,
        },{merge : true}).then(snap => {
            showToast("success", "Query Submitted Successfully");
            setName("");
            setEmail("");
            setMsg("");
        }).catch(err => {
            showToast("failed", "Something wrong");
        })
    }
    useEffect(() => {
        onTop();
    }, []);
  return (
    <React.Fragment>
      <Header></Header>
      <div className="loader" id="loader" style={{ display: "none" }}></div>            
      <section
        className="features-hero mt-5"
        style={{"padding-top":"25px",
          backgroundImage: `url("../assets/images/banners/contact_us_banner.jpg")`,
        }}
      >
        <div className="py-5" style={{"margin-top": "92px"}}> 
          <div className="row py-3">
            <div className="col-12 text-center">
            <p className="feature-hero-head"></p>
              <p className="feature-hero-sub-head">Contact Us</p>
            </div>
          </div>
        </div>
      </section>            
      <div class="content ">    
        <div class="container card">
        <div class="row"  style={{"justify-content":"center"}}>
            <div class="col-md-5 mr-auto">
            <h2>Contact Us</h2>
            <p class="mb-5" style={{"margin-top":"20px","color":"grey"}}>If you're looking for a sales-oriented approach, you can use this one from Unobi Lab's Contact Us page. It gets the sales process moving right away by encouraging users to enter their email address to learn more about the company and its offers.</p>

            <ul class="list-unstyled pl-md-5 mb-5">
                <li class="d-flex text-black mb-2">
                <span class="mr-3"><span class="fa fa-home" style={{"padding-top":"7px"}}></span></span>&nbsp;&nbsp; Opposite railway station, sivakasi, tamilnadu, india.
                </li>
                <li class="d-flex text-black mb-2"><span class="fa fa-phone"  style={{"padding-top":"7px"}}> </span>&nbsp;&nbsp; +91 6369606204</li>
                <li class="d-flex text-black"><span class="fa fa-envelope"  style={{"padding-top":"7px"}}> </span>&nbsp;&nbsp; support@unobilabs.com </li>
            </ul>
            </div>

            <div class="col-md-6" style={{"padding-top":"12px"}}>
                <div class="row">
                
                <div class="col-md-12 form-group">
                    <label for="name" class="col-form-label">Name</label>
                    <input type="text" onChange={(e) => setName(e.target.value)} class="form-control" name="name" id="name"/>
                </div>
                </div>
                <div class="row">
                <div class="col-md-12 form-group">
                    <label for="email" class="col-form-label">Email</label>
                    <input type="text" onChange={(e) => (setEmail(e.target.value), validateEmail(e))} class="form-control" name="email" id="email"/>
                    <span
                            style={
                              emailError === ""
                                ? {
                                    fontFamily: "raleway",
                                    fontWeight: "600",
                                    color: "#FFFFFF",
                                    fontSize: "14px",
                                  }
                                : {
                                    fontFamily: "raleway",
                                    fontWeight: "400",
                                    color: "#E11B2F",
                                    fontSize: "14px",
                                  }
                            }
                          >
                            {emailError}
                          </span>                    
                </div>
                </div>

                <div class="row">
                <div class="col-md-12 form-group">
                    <label for="message" class="col-form-label">Message</label>
                    <textarea class="form-control" onChange={(e) => setMsg(e.target.value)} name="message" id="message" cols="30" rows="7"></textarea>
                </div>
                </div>
                <div class="row">
                <div class="col-md-12"  style={{"padding-top":"12px"}}>
                    <input type="button" onClick={submitData} value="Send Message" class="btn btn-primary rounded-0 py-2 px-4"
                          disabled={
                            name === "" ||
                            msg === "" ||
                            email === "" ||
                            emailError !== "" 
                              ? true
                              : false
                          }                    
                    />
                    <span class="submitting"></span>
                </div>
                </div>
    
            </div>
        </div>

        
        
        </div> </div>
     <div id="snackbar" style={{"background-color": "#333"}}></div>     
      <Footer></Footer>
    </React.Fragment>
  );
};

export default ContactUsPage;
