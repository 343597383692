import React, { useEffect,useState } from "react";
import { useLocation,useParams } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Downloadapp from "../../components/DownloadApp/DownloadApp";
import db from "../../components/Firebase";
import { NavLink } from "react-router-dom";
import useDocumentTitle from '../../useDocumentTitle';

const CategoryPage = () => {  
  const [categoryproduct,setCategoryProduct]=useState([]);     
  const routePath = useLocation();    
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  let docid = useParams();
  const fetchBlogs = async () => {
    document.getElementById("loader").style.display = "block";    
    await db.collection("unobi_companies").doc("RHVR5A").collection("categories").get().then((querySnapshot) => {             
      querySnapshot.forEach(element => {
          var data = element.data();
          setCategoryProduct(arr => [...arr , data]);                            
      });
  })            
  document.getElementById("loader").style.display = "none";                         
  };  
  const categoryDetails = (id) => {
    let replace1 = id.replace(/ /gi,'_');            
    let link = '../category/' + replace1;    
    window.location.href = link;
  }
  useEffect(() => {
    setCategoryProduct([]);
    onTop();
    fetchBlogs().then(snap => {
    })   
  }, [routePath]);
  return (
    <React.Fragment>
      <Header name="CategoryPage"></Header>
      <div className="loader" id="loader" style={{ display: "none" }}></div>            
      <section
        className="features-hero mt-5"
        style={{ "padding-top":"25px",
          backgroundImage: `url("../assets/images/home-banner/product_banner.jpg")`,
        }}
      >
        <div className="container py-5">
          <div className="row py-3">
            <div className="col-12 text-center">
            <p className="feature-hero-head">Categories</p>
              <p className="feature-hero-sub-head"></p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row" style={{"padding-top":"20px"}}>
          {
				categoryproduct && categoryproduct.map(blog=>{
                    return(
                      <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6 d-flex" onClick={() => categoryDetails(blog.c_name)}>
                        <div className="card card-raised chartpadding">
                          <div className="card-body text-center">
                              <div className="row gx-4">
                                  <div className="col-12 col-xxl-10">
                                    <img src={"data:image/jpeg;base64," + blog.c_img} className="img-fluid" alt={blog.c_name} loading="crazy"/>                                  
                                  </div>
                              </div>
                          </div>
                          <div className="">
                                <p className="text-center">{blog.c_name}</p>
                          </div>                          
                        </div>                                                      
                      </div>
                    )
                })
            }
        </div>   </div>
      </section>

      <Downloadapp></Downloadapp>
      <Footer></Footer>
    </React.Fragment>
  );
};

export default CategoryPage;
