import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";

export default function Auth() {
    let authentication = useNavigate();    
    useEffect(() => {
        authentication('/')
      }, []);
  return (
    <div></div>
  )
}
