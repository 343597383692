import React, { useEffect,useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import db from "../../components/Firebase";
import "./OrderDetails.css";
import useDocumentTitle from '../../useDocumentTitle';

const OrderDetails = () => {  
  useDocumentTitle('Unobilabs - Order details');    
    let [name, setName] = useState(localStorage.getItem('name'));      
    let [phone, setPhone] = useState(localStorage.getItem('phone'));                  
    let [email, setEmail] = useState(localStorage.getItem('email'));      
    let [profile, setProfile] = useState(localStorage.getItem('profile'));        
    let docid = useParams();
    const [blogs, setBlogs] = useState([]);    
    const [pro, setProducts] = useState([]);  
    const [cashtypes, setCash] = useState("none");     
    const [digitaltypes, setDigital] = useState("none");         
    const [discounttype, setDiscount] = useState("none");     
    const [loading, setLoading] = useState(true);        
    const onTop = () => {
        window.scrollTo(0, 0);
    };
    const gotoOrders = () => {
        window.location.href = '/orders';
    }    
    const fetchOrders = async () => {
      document.getElementById("loader").style.display = "block";      
      await db.collection('unobi_bills').where("bill_number","==",docid.id).where("b_to","==",phone)
        .onSnapshot(function(querySnapshot) { 
            var details = [];
            var items = [];
            querySnapshot.forEach(function(doc) {
              let data = doc.data();
              let storename = data['b_storename'];
              let storeaddress = data['b_storeaddress1'];
              let billdate = data['b_number'];
              let b_sgst_total = data['b_sgst_total'];
              let b_cgst_total = data['b_cgst_total'];
              let b_gst_total = data['b_gst_total'];
              let overalltotal = data['b_total'];
              let billing = data['b_billing'];
              let shipping = data['b_shipping'];
              let coins_used = data['b_payments']['u_coins'];
              let total_coins = data['b_payments']['total_coins'];   
              let b_user_gst = data['b_user_gst'];           
              for(let i = 0; i < data['b_products'].length; i++)
              {
                let barcode = data['b_products'][i];
                if(data['products'][barcode]['p_qty'] > 0)
                {
                  let p_name = data['products'][barcode]['p_name'];
                  let p_qty = data['products'][barcode]['p_qty'];
                  let salesprice = data['products'][barcode]['p_salesprice'];
                  let total = data['products'][barcode]['p_total'];
                  let mrp = data['products'][barcode]['p_mrp'];
                  let gst = data['products'][barcode]['p_gst'];
                  if(data['products'][barcode]['p_barcode_available'] == "Scale")
                  {
                    p_qty = p_qty + 'Kg';
                  }
                  else{
                    p_qty = p_qty;                  
                  }
                  items.push({p_name,p_qty,salesprice,total,mrp,gst,barcode});                
                }
              }
              let paidamounts = '';
              let tenders = '';
              let paidamount = '';
              let tender = '';            
              let cashtype = '';
              let cardtype = '';
              let totals = '';
              let discount = '';
              let p_type = data['b_payments']['p_type'];
              let sub_total = '';
              let disco = '';
              let t_id = '';
              if(p_type == "online")
              {
                sub_total = data['b_payments']['b_total'];
                disco = data['b_payments']['percentage'];
                t_id = data['b_payments']['p_transaction_id'];
                setDiscount("block");
                setCash("block");
              }
              else if(p_type == "cash on payment")
              {
                sub_total = data['b_payments']['b_total'];
                disco = data['b_payments']['percentage'];                
                setDiscount("block");
              }
              else{
                sub_total = data['b_payments']['b_total'];
              }
              details.push({ storename,storeaddress,billdate,b_sgst_total,b_cgst_total,b_gst_total,overalltotal,paidamount,tender,paidamounts,tenders,cashtype,cardtype,totals,discount,sub_total,disco,p_type,t_id,billing,shipping,coins_used,total_coins,b_user_gst });
            });
            setProducts(items);
            setBlogs(details);
            setLoading(false);
        })      
        document.getElementById("loader").style.display = "none";                                 
    };
    const logout = () => {
      localStorage.clear();        
      window.location.reload();
    }    
    useEffect(() => {
        onTop();
        fetchOrders();
    }, []);
  return (
    <React.Fragment>
      <Header name="OrderDetailsPage"></Header>
      <div className="loader" id="loader" style={{ display: "none" }}></div>                  
      <section
        className="features-hero mt-5"
        style={{ "padding-top":"25px",
          backgroundImage: `url("../assets/images/banners/dashboard_banner.jpg")`,
        }}
      >
        <div className="container py-5">
          <div className="row py-3">
            <div className="col-12 text-center">
            <p className="feature-hero-head">Order Details</p>
              <p className="feature-hero-sub-head">{localStorage.getItem("name")}</p>
            </div>
          </div>
        </div>
      </section>      

      <div className="container">
      <div class="row">
        <div class="col-lg-4">
            <div class="card mb-4">
            <div class="card-body text-center">
                <img src={"data:image/jpeg;base64," + profile} alt="avatar"
                class="rounded-circle img-fluid" style={{"width": "140px","height":"140px"}}/>
                <h5 class="my-3">{name}</h5>
                <p class="text-muted mb-1">{phone}</p>
                <p class="text-muted mb-4">{email}</p>
                <div class="d-flex justify-content-center mb-2">
{/*                <button type="button" onClick={gotoOrders} class="btn btn-primary" style={{"width": "140px","background-color": "#373091!important"}}>My Orders</button>
                <button type="button" onClick={logout} class="btn btn-outline-primary ms-1" style={{"width" :"140px","border-color": "#373091!important","color": "#373091!important"}}>Logout</button> */}
                </div>
            </div>
            </div>        
        </div>
        {loading ? (
          <div class="col-md-8 col-xl-8 col-lg-8">
              <h3>Loading bill...</h3>
          </div>
        ) : (
          <div class="col-md-8 col-xl-8 col-lg-8">
          <div className="cards card_mods">
                {
                  blogs.map((doc) => (
                  <div className="card-body" style={{
                    backgroundImage: `url("../assets/img/bills/billcenter.png")`,"background-repeat": "repeat-y","background-size": "385px 437px"
                  }}>
                    <div className="row" style={{"padding-bottom":"16px"}}>
                      <div className="col-12">
                        <p className="card_head pt-1 text-left" style={{"margin-bottom":"-5px"}}>{ doc.storename }</p>
                      </div>
                      <div className="col-12">
                        <p className="pt-1 text-left">{ doc.storeaddress }</p>
                      </div>                    
                      <div className="col-12">
                        <p className="pt-1 text-left"  style={{"margin-top":"15px","color":"#303791"}}><b>{ new Intl.DateTimeFormat('en-IN', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(doc.billdate) }</b></p>
                      </div>                                        
                    </div>
                     
                    <div className="row tableHead">
                      <div className="col-4">
                        <p className="card_texts">
                          PRODUCT
                        </p>
                      </div>
                      <div className="col-2">
                        <p className="card_texts">
                          QTY
                        </p>
                      </div>
                      <div className="col-3">
                        <p className="card_texts">
                          RATE
                        </p>
                      </div>
                      <div className="col-2">
                        <p className="card_texts">
                          TOTAL
                        </p>
                      </div>
                      <div className="col-12">
                        
                      </div>
                    </div>
                    {
                      pro.map((docs) => (
                    <div className="row pt-3">
                      <div className="col-4">
                        <p className="card_textss"><b>
                          { docs['p_name'] }</b><br></br>Mrp: &#8377;{ docs['mrp'] },GST: { docs['gst'] }%
                        </p>
                      </div>
                      <div className="col-2">
                        <p className="card_textss"><b>
                        { docs['p_qty'] }</b>
                        </p>
                      </div>
                      <div className="col-3">
                        <p className="card_textss"><b>
                        { docs['salesprice'] }</b>
                        </p>
                      </div>
                      <div className="col-2">
                        <p className="card_textss text-right"><b>
                        { docs['total'] }</b>
                        </p>
                      </div>
                    </div>       
                    ))
                  }
                        <hr className="dotted"></hr>
                     <div className="row ">
                      <div className="col-4">
                      </div>
                      <div className="col-2">
                      </div>
                      <div className="col-3">
                        <p className="card_textss"><b>
                          SGST</b>
                        </p>
                      </div>
                      <div className="col-2">
                        <p className="card_textss"><b>
                        { doc.b_sgst_total }</b>
                        </p>
                      </div>
                    </div>                               
                    <div className="row ">
                      <div className="col-4">
                      </div>
                      <div className="col-2">
                      </div>
                      <div className="col-3">
                        <p className="card_textss"><b>
                          CGST</b>
                        </p>
                      </div>
                      <div className="col-2">
                        <p className="card_textss"><b>
                        { doc.b_cgst_total }</b>
                        </p>
                      </div>
                    </div>      
                    <div className="row">
                      <div className="col-4">
                      </div>
                      <div className="col-2">
                      </div>
                      <div className="col-3">
                        <p className="card_textss"><b>
                          Total Tax</b>
                        </p>
                      </div>
                      <div className="col-2">
                        <p className="card_textss"><b>
                        { doc.b_gst_total }</b>
                        </p>
                      </div>
                    </div>      
                    <div className="row">
                      <div className="col-4">
                      </div>
                      <div className="col-2">
                      </div>
                      <div className="col-3">
                        <p className="card_textss"><b>
                           Sub total</b>
                        </p>
                      </div>
                      <div className="col-2">
                        <p className="card_textss"><b>
                          { doc.sub_total }</b>
                        </p>
                      </div>
                    </div>    
                    {
                      doc.coins_used == "Yes" ? (
                        <div>
                        <div className="row ">
                          <div className="col-4">
                          </div>
                          <div className="col-2">
                          </div>
                          <div className="col-3">
                            <p className="card_textss"><b>
                              Coins Used</b>
                            </p>
                          </div>
                          <div className="col-2">
                            <p className="card_textss"><b>
                              -{ doc.total_coins }</b>
                            </p>
                          </div>
                        </div>             
                      </div>                                                            
                      ) : (
                        <span></span>
                      )
                    } 
                    <div id="discount" style={{"display" : discounttype}}>
                      <div className="row ">
                        <div className="col-4">
                        </div>
                        <div className="col-2">
                        </div>
                        <div className="col-3">
                          <p className="card_textss"><b>
                            Discount</b>
                          </p>
                        </div>
                        <div className="col-2">
                          <p className="card_textss"><b>
                            { doc.disco }% Off</b>
                          </p>
                        </div>
                      </div>             
                    </div>                                        
                    <div className="row">
                      <div className="col-4">
                      </div>
                      <div className="col-2">
                      </div>
                      <div className="col-3">
                        <p className="card_textss"><b>
                           Total</b>
                        </p>
                      </div>
                      <div className="col-2">
                        <p className="card_textss"><b>
                          { doc.overalltotal }</b>
                        </p>
                      </div>
                    </div>  
                    <div id ="paidbycash">
                      <hr className="tophr"></hr>
                      <div className="row pt-3">
                        <div className="col-6">
                        <p className="card_textss" style={{"text-align":"left"}}>
                            Paid By : <b>{ doc.p_type }</b> 
                            
                          </p>
                        </div>
                        
                        <div className="col-6">
                          <p className="card_textss" style={{"text-align":"right"}}>
                            <span style={{"display" : cashtypes}} >Transaction Id : <b>{ doc.t_id }</b></span>
                          </p>
                        </div>
                      </div>
                      <div className="row pt-3">
                        <div className="col-6">
                        <p className="card_textss" style={{"text-align":"left"}}>
                            {
                              doc.b_user_gst ? (
                                <span>GST Number : <b>{ doc.b_user_gst }</b></span>                             
                              ) : (
                                <span></span>
                              )
                            }
                          </p>
                        </div>
                        
                        <div className="col-6">
                          <p className="card_textss" style={{"text-align":"right"}}>
                          {
                              doc.shipping['b_alt_phone'] ? (
                                <span >Alternate Number : <b>{ doc.shipping['b_alt_phone'] }</b></span>
                              ) : (
                                <span></span>
                              )
                            }                            
                          </p>
                        </div>
                      </div>                      
                    </div>
                    <div id="paidbydigital" style={{"display" : digitaltypes}}>
                      <hr className="tophr"></hr>        
                      <div className="row pt-3">
                        <div className="col-4">
                        <p className="card_textss"><b>
                            Digital Payment</b>
                          </p>                      
                        </div>
                        <div className="col-2">
                        </div>
                        <div className="col-3">
                        </div>
                        <div className="col-2">
                        </div>
                      </div>           
                      <hr className="bottomhr"></hr>     
                      <div className="row pt-3">
                        <div className="col-2">
                        </div>
                        <div className="col-3">
                        </div>
                        <div className="col-3">
                        </div>
                        <div className="col-4">
                          <p className="card_textss"><b>
                            Total : { doc.paidamount }</b><br></br>
                            Paid By : { doc.tender }
                          </p>
                        </div>
                      </div>      
                      <hr className="tophr"></hr>

                      <div className="row pt-3">
                        <div className="col-2">
                        </div>
                        <div className="col-3">
                        </div>
                        <div className="col-3">
                        </div>
                        <div className="col-4">
                          <p className="card_textss"><b>
                            Gst Number : { doc.b_user_gst }</b><br></br>
                            Alternate Number : { doc.shipping['b_alt_phone'] }
                          </p>                          
                        </div>
                      </div>                                        
                    </div>                                                                                                                                                                                                                    
                    <div>
                      <hr className="tophr"></hr>
                         
                      <div className="row pt-3">
                        <div className="col-6">
                        <p className="card_textss" style={{"text-align":"left"}}>
                            <b>Billing Address</b> 
                          </p>
                          <p className="card_textss" style={{"text-align":"left"}}>
                            {doc.billing['b_name']}, <br></br>                            
                            {doc.billing['b_door']}, {doc.billing['b_address']}, <br></br>
                            {doc.billing['b_district']}, {doc.billing['b_city']}, {doc.billing['b_pincode']}, <br></br>
                            {doc.billing['b_state']}, {doc.billing['b_country']}
                          </p>                        
                        </div>
                        
                        <div className="col-6">
                          <p className="card_textss" style={{"text-align":"right"}}>
                            <b>Shipping Address </b>
                          </p>
                          <p className="card_textss" style={{"text-align":"right"}}>
                            {doc.shipping['s_name']}, <br></br>                            
                            {doc.shipping['s_door']}, {doc.shipping['s_address']}, <br></br>
                            {doc.shipping['s_district']}, {doc.shipping['s_city']}, {doc.shipping['s_pincode']}, <br></br>
                            {doc.shipping['s_state']}, {doc.shipping['s_country']}
                          </p>                                                
                        </div>
                      </div>
                    </div>                  
                  </div>
                    ))
                  }
                </div>
          </div>          
        )}
     </div>
     </div>     
      <Footer></Footer>
    </React.Fragment>
  );
};

export default OrderDetails;