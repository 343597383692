import React, { useEffect,useState } from "react";
import { Link } from "react-router-dom";
import "./DownloadApp.css";
import db from "../../components/Firebase";
import validator from "validator";

const Downloadapp = (props) => {
  let [email, setEmail] = useState("");    
  let [emailError, setEmailError] = useState("");

  const validateEmail = (e) => {
    var email = e.target.value;
    if (validator.isEmail(email)) {
      setEmailError("");
    } else {
      setEmailError("Please provide valid Email.");
    }
  };    
  const showToasts = (types,text) => {
    if(types == "failed")
    {
      var x = document.getElementById("snackbar");
      x.className = "show";
      x.style.backgroundColor = "#de3d3d";      
      setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);            
      document.getElementById("snackbar").innerText = text;
    }
    else
    {
      var x = document.getElementById("snackbar");
      x.className = "show";
      x.style.backgroundColor = "#21b446";
      setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);            
      document.getElementById("snackbar").innerText = text;
    }
  }        
  const submitNews = async () => {
      let timestamap = new Date().getTime();     
      let string = timestamap + '';   
      await db.collection("unobi_newsletter").doc(string).set({
          "c_email" : email,
          "time" :  timestamap,
      },{merge : true}).then(snap => {
          showToasts("success", "Submitted Successfully");
          setEmail("");
      }).catch(err => {
          showToasts("failed", "Something wrong");
      })
  }  
  return (
    <section className="py-2">
    <div className="container mt-5">
      <div className="row">
        <div className="col-12">
          <div className="card card-download-mod py-4">
            <div className="card-body text-center">
              <p className="store-hero-head-sel">
                  Now unobilabs available on Playstore download Now
              </p>
              <a
                href=""
                className="btn btn-lg apk_btn_mod-sel_white ms-lg-3 mt-3"
              >
                <i class="fa-brands fa-google-play pe-3"></i>
                Google Play
              </a>
            </div>
          </div>
        </div>
      </div>
      <br/>
      <div className="col-md-12" style={{"padding":"21px","background-image":"url(https://refreshtechlabs.com/unobilabs_newsletter_bg.png)","border-radius":"10px","background-repeat": "no-repeat","background-size": "cover"}}>
        <h1 className="store-hero-head-sel" style={{"text-align":"center","color":"white"}}>Subscribe to our Newsletter</h1>
        
      <div className="col-md-12"  style={{"padding-top":"20px",}}>
        
       <div className="col-md-12" style={{"text-align":"-webkit-center"}}>
         <input type = "text" id="floatingInput"   value={email} onChange={(e) => (setEmail(e.target.value), validateEmail(e))} className="form-control mob-res-width" placeholder="Enter valid E-mail address" style={{"height":"47px","text-align":"center"}} />
         <span
            style={
              emailError === ""
                ? {
                    fontFamily: "raleway",
                    fontWeight: "600",
                    color: "#FFFFFF",
                    fontSize: "14px",
                  }
                : {
                    fontFamily: "raleway",
                    fontWeight: "400",
                    color: "#E11B2F",
                    fontSize: "14px",
                  }
            }
          >
            {emailError}
          </span>                             
        </div>
         <div className="col-md-12" style={{"text-align":"center","padding-top":"15px"}}>
          <button className="myButton" onClick={submitNews} style={{"margin-right":"0px"}}
                          disabled={
                            email === "" ||
                            emailError !== "" 
                              ? true
                              : false
                          }                              
          >Submit</button>
         </div>
       </div></div>      
    </div>
    <div>
      <br/>
    <div class="row row-cols-xl-3 row-cols-sm-2 row-cols-1 row--20">
                    <div>
                        <div>
                            <center>
                                <img src="/assets/images/logo/best_price.svg" alt="Service"/>
                            </center>
                            <center>
                                <h6 class="title">Best Price.</h6>
                                <p>High quality, low price!.</p>
                            </center>
                        </div>
                    </div>
                    <div>
                        <div class="service-box service-style-2">
                            <center>
                                <img src="/assets/images/logo/great_daily_deals.svg" alt="Service"/>
                            </center>
                            <center>
                                <h6 class="title">Great Daily Deals</h6>
                                <p>Everyday get a best deals.</p>
                            </center>
                        </div>
                    </div>
                     
                    <div>
                        <div class="service-box service-style-2">
                            <center>
                                <img src="/assets/images/logo/wide_assortment.svg" alt="Service"/>
                            </center>
                            <center>
                                <h6 class="title">Wide assortment</h6>
                                <p>we have Wide assortment.</p>
                            </center>
                        </div>
                    </div>
                </div>
    </div>
    <div id="snackbar" style={{"background-color": "#333"}}></div>
  </section>

    );
};

export default Downloadapp;
   
