import React, { useEffect,useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import db from "../../components/Firebase";
import "./OrdersPage.css";
import useDocumentTitle from '../../useDocumentTitle';

const OrdersPage = () => {  
    useDocumentTitle('Unobilabs - Orders');  
    let [name, setName] = useState(localStorage.getItem('name'));      
    let [phone, setPhone] = useState(localStorage.getItem('phone'));                  
    let [email, setEmail] = useState(localStorage.getItem('email'));      
    let [profile, setProfile] = useState(localStorage.getItem('profile'));        
    let [orders, setOrders] = useState([]);
    let [emptyBill, setEmpty] = useState(false);    
    let [limit, setLimit] = useState(11);
    const onTop = () => {
        window.scrollTo(0, 0);
    };
    const gotoProfile = () => {
        window.location.href = '/profile';
    }    
    const fetchOrders = async () => {
        document.getElementById("loader").style.display = "block";                                 
        await db.collection("unobi_bills").where("b_to","==",phone).where("b_status","in",["Process","Packed","Indelivery","Delivered"]).orderBy("b_number","desc").limit(limit).get().then((querySnapshot) => {             
            if(querySnapshot.empty){
                setEmpty(true);
            }
            else
            {
                setEmpty(false);
                querySnapshot.forEach(element => {
                    var data = element.data();
                    setOrders(arr => [...arr , data]);                            
                });    
            }
        });
        document.getElementById("loader").style.display = "none";                                 
    };
    const loadmore = async () => {
        document.getElementById("loadmore").innerText = "Loading...";
        setLimit(limit + 10);
        const first = db.collection("unobi_bills").where("b_to","==",phone).where("b_storecode","==","RHVR5A").where("b_status","in",["Process","Packed","Indelivery","Delivered"]).orderBy('b_number','desc').limit(limit);
        const snapshot = await first.get();
        const last = snapshot.docs[snapshot.docs.length - 1];
        const next = await db.collection("unobi_bills").where("b_to","==",phone).where("b_storecode","==","RHVR5A").where("b_status","in",["Process","Packed","Indelivery","Delivered"]).orderBy('b_number','desc').startAfter(last.data().b_number).limit(10).get().then((querySnapshot) => {             
            if(querySnapshot.empty){
                showToast("failed","No more data found");                            
                document.getElementById("loadmore").innerText = "load more";                                
            }
            else
            {
                setEmpty(false);
                document.getElementById("loadmore").innerText = "load more";                
                querySnapshot.forEach(element => {
                    var data = element.data();
                    setOrders(arr => [...arr , data]);                            
                });    
            }
        });
    }
    const showToast = (types,msg) => {
        if(types == "failed")
        {
          var x = document.getElementById("snackbar");
          x.className = "show";
          x.style.backgroundColor = "#de3d3d";      
          setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);            
          document.getElementById("snackbar").innerText = msg;
        }
        else
        {
          var x = document.getElementById("snackbar");
          x.className = "show";
          x.style.backgroundColor = "#21b446";
          setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);            
          document.getElementById("snackbar").innerText = msg;
        }
      }    
    const logout = () => {
        localStorage.clear();        
        window.location.reload();
    }    
    const viewBill = (id) => {
        window.location.href = '/bill/' + id;
    }
    useEffect(() => {
        onTop();
        fetchOrders()
    }, []);
  return (
    <React.Fragment>
      <Header name="OrdersPage"></Header>
      <div className="loader" id="loader" style={{ display: "none" }}></div>                  
      <section
        className="features-hero mt-5"
        style={{ "padding-top":"25px",
          backgroundImage: `url("../assets/images/banners/dashboard_banner.jpg")`,
        }}
      >
        <div className="container py-5">
          <div className="row py-3">
            <div className="col-12 text-center">
            <p className="feature-hero-head">Orders</p>
              <p className="feature-hero-sub-head">{localStorage.getItem("name")}</p>
            </div>
          </div>
        </div>
      </section>      

      <div className="container">
      <div class="row">
        <div class="col-lg-4">
            <div class="card mb-4">
            <div class="card-body text-center">
                <img src={"data:image/jpeg;base64," + profile} alt="avatar"
                class="rounded-circle img-fluid" style={{"width": "140px","height":"140px"}}/>
                <h5 class="my-3">{name}</h5>
                <p class="text-muted mb-1">{phone}</p>
                <p class="text-muted mb-4">{email}</p>
                <div class="d-flex justify-content-center mb-2">
{/*                <button type="button" onClick={gotoProfile} class="btn btn-primary" style={{"width": "140px","background-color": "#373091!important"}}>My Account</button>
                <button type="button" onClick={logout} class="btn btn-outline-primary ms-1" style={{"width" :"140px","border-color": "#373091!important","color": "#373091!important"}}>Logout</button> */}
                </div>
            </div>
            </div>
        
        </div>
        <div class="col-md-8 col-xl-8 col-lg-8">
        {
        emptyBill ? (
            <div class="card shadow-0 border rounded-3 my-2">
                <div class="card-body">
                    <center>
                        <img src="/assets/images/logo/no_bills.png"></img>
                    </center>                        
                </div>
            </div>                
        ) : (
            orders && orders.map(blog=>{
                let timestamp_int_convert = blog.b_timestamp;
                let date = new Date(timestamp_int_convert*1000);
                var date_creation = new Intl.DateTimeFormat('en-IN', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(date)                
                var s1 = blog.bill_number;
                var s2 = s1.substring(7);                    
                return (
                    <div class="card shadow-0 border rounded-3 my-2">
                    <div class="card-body">
                        <div class="row">
                        <div class="col-md-12 col-lg-3 col-xl-3 mb-4 mb-lg-0" style={{"width":"80px"}}>
                            <div class="bg-image hover-zoom ripple rounded ripple-surface">
                                {
                                    blog.b_status == "Process" ? (
                                        <img src="./assets/images/logo/process.svg"
                                        class="w-100" />        
                                    ) : (
                                        <span></span>
                                    )
                                }
                                {
                                    blog.b_status == "Packed" ? (
                                        <img src="./assets/images/logo/packed.svg"
                                        class="w-100" />        
                                    ) : (
                                        <span></span>
                                    )
                                }                                    
                                {
                                    blog.b_status == "Indelivery" ? (
                                        <img src="./assets/images/logo/indelivery.svg"
                                        class="w-100" />        
                                    ) : (
                                        <span></span>
                                    )
                                }                                                                        
                                {
                                    blog.b_status == "Delivered" ? (
                                        <img src="./assets/images/logo/delivered.svg"
                                        class="w-100" />        
                                    ) : (
                                        <span></span>
                                    )
                                }                                                                                                            
                            <a href="#!">
                                <div class="hover-overlay">
                                <div class="mask" style={{"background-color": "rgba(253, 253, 253, 0.15)"}}></div>
                                </div>
                            </a>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6 col-xl-6">
                            <h5 style={{"marginTop":"10px"}}>Bill No : {s2}</h5>
                            <p class="text-truncate mb-4 mb-md-0" style={{"font-size":"14px","font-weight": "600","color": "grey;"}}>
                             Status : {blog.b_status}
                            </p>
                            <p class="text-truncate mb-4 mb-md-0" style={{"font-size":"14px"}}>
                            Date : {date_creation}
                            </p>
                        </div>
                        <div class="col-md-6 col-lg-3 col-xl-3 border-sm-start-none border-start justify-content-between">
                            <div class="d-flex flex-row align-items-center mb-1">
                                    <h4 class="mb-1 me-1">&#8377;{blog.b_total}</h4>
                            </div>
                            <div class="d-flex flex-column mt-4">
                            <button class="btn btn-primary btn-sm" style={{"background-color": "#373091", "height": "45px"}} onClick={() => viewBill(blog.bill_number)} type="button">View Details</button>                                
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                )
            })
        )
            }
        </div>
        <div class="col-lg-4"></div>
        <div class="col-md-8 col-xl-8 col-lg-8">   
            <button id="loadmore" className="loadMore" onClick={loadmore}>load more</button>        
        </div>
     </div>
     </div>
     <div id="snackbar" style={{"background-color": "#333"}}></div>     
      <Footer></Footer>
    </React.Fragment>
  );
};

export default OrdersPage;
