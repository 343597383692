import React, { useEffect,useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import db from "../../components/Firebase";
import validator from "validator";
import useDocumentTitle from '../../useDocumentTitle';

const AboutUs = () => {  
  useDocumentTitle('Unobilabs - Contact us');  
    let [name, setName] = useState("");
    let [email, setEmail] = useState("");    
    let [msg, setMsg] = useState("");    
    let [emailError, setEmailError] = useState("");
    const onTop = () => {
        window.scrollTo(0, 0);
    };
    const validateEmail = (e) => {
        var email = e.target.value;
        if (validator.isEmail(email)) {
          setEmailError("");
        } else {
          setEmailError("Please provide valid Email.");
        }
      };    
      const showToast = (types,text) => {
        if(types == "failed")
        {
          var x = document.getElementById("snackbar");
          x.className = "show";
          x.style.backgroundColor = "#de3d3d";      
          setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);            
          document.getElementById("snackbar").innerText = text;
        }
        else
        {
          var x = document.getElementById("snackbar");
          x.className = "show";
          x.style.backgroundColor = "#21b446";
          setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);            
          document.getElementById("snackbar").innerText = text;
          window.location.reload();
        }
      }        
    const submitData = async () => {
        let timestamap = new Date().getTime();     
        let string = timestamap + '';   
        await db.collection("unobi_contactus").doc(string).set({
            "c_name" : name,
            "c_email" : email,
            "c_msg" : msg,
            "time" :  timestamap,
        },{merge : true}).then(snap => {
            showToast("success", "Query Submitted Successfully");
            setName("");
            setEmail("");
            setMsg("");
        }).catch(err => {
            showToast("failed", "Something wrong");
        })
    }
    useEffect(() => {
        onTop();
    }, []);
  return (
    <React.Fragment>
      <Header></Header>
      <div className="loader" id="loader" style={{ display: "none" }}></div>            
      <section
        className="features-hero mt-5"
        style={{"padding-top":"25px",
          backgroundImage: `url("../assets/images/banners/aboutus_banner.jpg")`,
        }}
      >
        <div className="py-5" style={{"margin-top": "92px"}}> 
          <div className="row py-3">
            <div className="col-12 text-center">
            <p className="feature-hero-head"></p>
              <p className="feature-hero-sub-head">About Us</p>
            </div>
          </div>
        </div>
      </section>            
      <div class="content ">    
        <div class="container ">
        <div class="row"  style={{"justify-content":"center"}}>
            <div class="col-md-6 mr-auto">
                <center>
            <img src="../assets/images/banners/aboutus_page_image.jpg" style={{"border-radius":"10px"}} alt="unobilabs" />
            </center>
            </div>

            <div class="col-md-6 mr-auto">
            <br/> <br/> 
            <h2>About Us</h2>
            <p class="mb-5" style={{"margin-top":"20px","color":"grey"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <br/>
         
          
            <p class="mb-5" style={{"margin-top":"20px","color":"grey"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
           
            </div>
        </div>

        
        
        </div> </div>
     <div id="snackbar" style={{"background-color": "#333"}}></div>     
      <Footer></Footer>
    </React.Fragment>
  );
};

export default AboutUs;
