import React from "react";
import './Brand.css';
import { BrandData } from "../../Data/BrandData";

function Brand () {
    const redirectUrl = (url) =>{
        window.location.href = '/brand/' + url;
    }
    function leftScroll() {
        const left = document.querySelector(".scroll-images");
        left.scrollBy(200, 0);
      }
      function rightScroll() {
        const right = document.querySelector(".scroll-images");
        right.scrollBy(-200, 0);
      }    
    return (
        <React.Fragment>
            <div className="position-relative pt-9 pt-lg-8 pb-6 pb-lg-8">
                <div className="">
                    <div className="row row-cols-lg-6 row-cols-md-5 row-cols-3 flex-center">
                           <div class="scroll-images">                        
                            {BrandData.map((ex) => (                        
                                <div className="col">
                                    <div className="card shadow-hover mb-4 child" onClick={(e)=>redirectUrl(ex.img_head)}>
                                        <div className="card-body text-center rounded-circle child-img"> <img src={ex.img_url} class="rounded-circle" alt={ex.img_head} style={{"width":"115px"}}/></div>
                                    </div>
                                </div>
                            ))}                            
                           </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Brand;